<!--
 * @Description: 选择安装场景
 * @Author: ChenXueLin
 * @Date: 2021-08-24 15:03:39
 * @LastEditTime: 2022-06-15 16:00:29
 * @LastEditors: ChenXueLin
-->

<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="选择安装场景"
      :visible="selectSceneDialog"
      width="1200px"
      v-loading="dialogLoading"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      :before-close="handleClose"
      custom-class="install-scene-dialog"
    >
      <div>
        <div class="select-scene-content">
          <!-- 左侧tab start -->
          <section class="tree">
            <div class="category-content">
              <div
                :class="['category', clickIndex == index ? 'active' : '']"
                v-for="(item, index) in sceneList"
                :key="item.sceneType"
                @click="handleClickTab(index, item.sceneTypeName)"
              >
                {{ item.sceneTypeName }}({{ item.count }})
              </div>
            </div>
          </section>
          <!-- 左侧tab end -->
          <section class="table">
            <el-form class="search-list" ref="searchForm" :model="searchForm">
              <el-form-item class="search-item--1" prop="sceneNameList">
                <input-plus
                  v-model="searchForm.sceneNameList"
                  valueType="Array"
                  placeholder="场景名称"
                  title="场景名称"
                  label="场景名称"
                  clearable
                ></input-plus>
              </el-form-item>
              <el-form-item class="search-item--buttons">
                <el-button type="primary" @click="handleSearch">查询</el-button>
                <el-button type="text" v-if="errorSum > 0" @click="lookNum"
                  >问题数量:{{ errorSum }}</el-button
                >
                <!-- <el-button class="reset" @click="handleReset">重置</el-button> -->
              </el-form-item>
            </el-form>
            <el-table
              border
              :data="sceneTypeTableData"
              highlight-current-row
              ref="selectTable"
              height="350px"
            >
              <el-table-column
                prop="regName"
                label="系统车牌"
                width="200"
                align="center"
                header-align="center"
              >
                <template slot-scope="{ row }">
                  <span v-if="row.regName && row.isShow1">
                    {{ row.regName }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column
                prop="sceneName"
                label=""
                width="220"
                align="center"
                header-align="center"
              >
                <template v-slot:header>
                  <div>
                    <el-checkbox @change="handleSelectAll" v-model="checkAll"
                      >场景名称</el-checkbox
                    >
                  </div>
                </template>
                <template slot-scope="{ row }">
                  <el-checkbox
                    @change="handleChange(row, 1)"
                    v-if="row.sceneName && row.isShow1"
                    v-model="row.checked1"
                    >{{ row.sceneName }}</el-checkbox
                  >
                </template>
              </el-table-column>

              <el-table-column
                prop="secondClassName"
                label="安装组合"
                align="center"
                width="250"
                header-align="center"
              >
                <template slot-scope="{ row }">
                  <el-checkbox
                    @change="handleChange(row, 2)"
                    v-if="row.secondClassName && row.isShow2"
                    v-model="row.checked2"
                    >{{ row.secondClassName }}</el-checkbox
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="terStatus"
                label="终端状态"
                width="120"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="thirdClassName"
                label="已安装商品"
                align="center"
                width="260"
                header-align="center"
              >
                <template slot-scope="{ row }">
                  <el-checkbox
                    @change="handleChange(row, 3)"
                    v-if="row.thirdClassName && addType != 1"
                    v-model="row.checked3"
                    >{{ row.thirdClassName }}</el-checkbox
                  >
                  <span v-else>{{ row.thirdClassName }}</span>
                </template>
                <el-checkbox></el-checkbox>
              </el-table-column>

              <el-table-column
                prop="firstClassName"
                label="商品分类"
                width="200"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="equipCode"
                label="设备编号"
                width="180"
                align="center"
                header-align="center"
              >
              </el-table-column>
              <el-table-column
                prop="virtualTypeName"
                label="类型"
                width="180"
                align="center"
                header-align="center"
              >
              </el-table-column>
            </el-table>
          </section>
        </div>
        <section class="pagination-wrapper fixed-section">
          <el-pagination
            :page-size.sync="searchForm.pageSize"
            :current-page.sync="searchForm.pageIndex"
            :page-sizes="pageSizes"
            :layout="layout"
            :total="total"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </section>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
    <!-- 问题数量弹框 -->
    <el-dialog
      v-dialogDrag
      title="问题数量"
      :visible="numDialog"
      width="800px"
      :close-on-click-modal="false"
      :before-close="closeNumDialog"
      custom-class="install-scene-dialog"
    >
      <div class="numHeader">
        <span>共：{{ errorSum }}条</span>
        <el-button type="primary" @click="exportErrorByData"
          >导出数据</el-button
        >
      </div>
      <el-table :data="scenePageResults" height="500" highlight-current-row>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in numColumnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getSceneEquip, getSceneNumber } from "@/api";
import { printError } from "@/utils/util";
import inputPlus from "@/components/inputPlus";
import { exportXlsxByData } from "@/utils/download.js";
export default {
  name: "",
  components: { inputPlus },
  data() {
    return {
      checkAll: false,
      clickIndex: 0,
      sceneType: "", //左侧选中的场景类型
      searchForm: {
        sceneNameList: [],
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      equipIdList: [],
      sceneTypeTableData: [],
      selectRow: [], //选中的数据
      sceneList: [], //左侧导航菜单
      dialogLoading: false,
      /*******问题数量弹框*********/
      errorSum: 0,
      numDialog: false,
      numColumnData: [
        {
          fieldName: "sceneName",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "count",
          display: true,
          fieldLabel: "查询结果",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      scenePageResults: []
    };
  },
  props: [
    "selectSceneDialog",
    "unknownScene",
    "corpId",
    "terStatus",
    "addType"
  ],
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    selectSceneDialog(val) {
      if (val) {
        this.checkAll = false;
        this.selectRow = [];
        this.sceneTypeTableData = [];
        this.searchForm.pageIndex = 1;
        this.getSceneNumber();
      }
    }
  },
  mounted() {},
  methods: {
    handleSearch() {
      this.querySceneTypeList();
    },
    //处理返回的数据结构
    handleData(data) {
      let sceneTypeTableData = data;
      let result = [];
      let tableArr = [];
      //将三维数组合并内容转化为二维数组
      sceneTypeTableData.map(item => {
        if (item.terminalList && item.terminalList.length) {
          item.terminalList.map(each => {
            each = {
              sceneAddress: item.sceneAddress,
              sceneId: item.sceneId,
              sceneName: item.sceneName,
              regName: item.regName,
              sceneType: item.sceneType,
              sceneTypeName: item.sceneTypeName,
              vehicleId: item.vehicleId,
              vehicleColor: item.vehicleColor,
              vehicleColorName: item.vehicleColorName,
              equipList: each.equipList,
              realNo: each.realNo,
              isPrivate: each.isPrivate,
              combinationName: each.secondClassName,
              simId: each.simId,
              stopDate: each.stopDate,
              stopDateStr: each.stopDateStr,
              stopDateTimeStamp: each.stopDateTimeStamp,
              stopReason: each.stopReason,
              terminalId: each.terminalId,
              terStatus: each.terStatus,
              commids: each.commids
            };
            result.push(each);
          });
        }
      });
      //将二维数组合并内容转化为一维数组
      result.map(item => {
        if (item.equipList && item.equipList.length) {
          item.equipList.map(each => {
            each = {
              ...item,
              equipId: each.equipId,
              firstClassId: each.firstClassId,
              firstClassName: each.firstClassName,
              fixEndDateStr: each.fixEndDateStr,
              fixStartDate: each.fixStartDate,
              fixStartDateStr: each.fixStartDateStr,
              lifeCycle: each.lifeCycle,
              secondClassId: each.secondClassId,
              secondClassName: each.secondClassName,
              thirdClassId: each.thirdClassId,
              thirdClassName: each.thirdClassName,
              tmId: each.tmId,
              equipCode: each.equipCode,
              virtualType: each.virtualType,
              equipmentType: each.virtualType,
              virtualTypeName: each.virtualTypeName,
              installType: each.installType,
              installTypeName: each.installTypeName
            };
            each.checked1 = false;
            each.checked2 = false;
            each.checked3 = false;
            // if (each.equipId) {
            tableArr.push(each);
            // }
          });
        }
      });
      // 控制安装组合和已安装设备的显示隐藏
      for (let i = 0; i < tableArr.length; i++) {
        if (i == 0) {
          tableArr[i].isShow1 = true;
          tableArr[i].isShow2 = true;
        } else {
          if (
            tableArr[i].sceneId == tableArr[i - 1].sceneId &&
            tableArr[i].sceneName == tableArr[i - 1].sceneName
          ) {
            tableArr[i].isShow1 = false;
          } else {
            tableArr[i].isShow1 = true;
          }
          if (tableArr[i].terminalId == tableArr[i - 1].terminalId) {
            tableArr[i].isShow2 = false;
          } else {
            tableArr[i].isShow2 = true;
          }
        }
      }
      this.sceneTypeTableData = tableArr;
    },
    //获取场景个数
    async getSceneNumber() {
      try {
        this.dialogLoading = true;
        let res = await getSceneNumber({
          corpId: this.corpId,
          terStatus: this.terStatus
        });
        let sceneList = res.data;
        if (this.unknownScene) {
          sceneList.push({
            count: 0,
            sceneType: "",
            sceneTypeName: "未知场景"
          });
        }
        this.sceneList = sceneList;
        this.sceneType = this.sceneList[0].sceneType;
        this.clickIndex = 0;
        this.querySceneTypeList();
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取场景类型
    async querySceneTypeList() {
      try {
        this.dialogLoading = true;
        let res = await getSceneEquip({
          corpId: this.corpId,
          ...this.searchForm,
          equipIdList: this.equipIdList,
          terStatus: this.terStatus,
          sceneType: this.sceneType
        });
        let sceneTypeTableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.errorSum = this.getFreezeResponse(res, {
          path: "data.errorSum"
        });
        this.scenePageResults = this.getFreezeResponse(res, {
          path: "data.scenePageResults"
        });
        if (sceneTypeTableData.length) {
          this.handleData(_.cloneDeep(sceneTypeTableData));
        } else {
          this.sceneTypeTableData = _.cloneDeep(sceneTypeTableData);
        }
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.selectTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //选中操作
    handleChange(row, type) {
      let sceneTypeTableData = this.sceneTypeTableData;
      //处理全选、全不选
      if (type == 1) {
        // 点击场景名称
        let firstList = sceneTypeTableData.filter(item => {
          return item.sceneId == row.sceneId && item.regName == row.regName;
        });
        if (row.checked1) {
          //全选中
          firstList.map(item => {
            item.checked1 = true;
            item.checked2 = true;
            item.checked3 = true;
          });
        } else {
          //取消全选
          firstList.map(item => {
            item.checked1 = false;
            item.checked2 = false;
            item.checked3 = false;
          });
        }
      }
      if (type == 2) {
        //点击安装组合
        let secondList = sceneTypeTableData.filter(item => {
          return item.terminalId == row.terminalId;
        });
        if (row.checked2) {
          secondList.map(item => {
            item.checked2 = true;
            item.checked3 = true;
          });
        } else {
          secondList.map(item => {
            item.checked1 = false;
            item.checked2 = false;
            item.checked3 = false;
          });
        }
      }
      //判断是否应该全选
      //安装设备
      if (type == 3) {
        let thirdList = sceneTypeTableData.filter(item => {
          return item.terminalId == row.terminalId;
        });
        //如果是相同组合的设备的checked3都为true，那么组合全选选中，否则组合全选取消
        if (thirdList.every(item => item.checked3)) {
          thirdList.map(item => {
            item.checked2 = true;
          });
        } else {
          thirdList.map(item => {
            item.checked2 = false;
          });
        }
      }
      //过滤场景相同的数据
      let checkedList = sceneTypeTableData.filter(item => {
        return item.sceneId == row.sceneId && item.regName == row.regName;
      });
      //如果场景相同的数据的checked2都为true，那么场景全选选中，否则场景全选取消
      if (checkedList.every(item => item.checked2)) {
        checkedList.map(item => {
          item.checked1 = true;
        });
      } else {
        checkedList.map(item => {
          item.checked1 = false;
        });
      }
      this.sceneTypeTableData = sceneTypeTableData;
    },
    //拿到选中的数据
    getSelectData() {
      let selectRow = this.sceneTypeTableData.filter(item => item.checked3);
      for (let i = 0; i < selectRow.length; i++) {
        this.selectRow.push(selectRow[i]);
      }
    }, //
    // 页码改变
    handleCurrentChange(curPage) {
      this.getSelectData();
      this.searchForm.pageIndex = curPage;
      this.querySceneTypeList();
      this.scrollTop = 0;
    },
    //点击确定
    confirm() {
      this.getSelectData();
      this.$emit("getData", _.cloneDeep(this.selectRow));
      this.sceneTypeTableData.map(item => {
        item.checked1 = false;
        item.checked2 = false;
        item.checked3 = false;
      });
      this.selectRow = [];
    },
    //关闭弹框
    handleClose() {
      this.checkAll = false;
      this.sceneTypeTableData.map(item => {
        item.checked1 = false;
        item.checked2 = false;
        item.checked3 = false;
      });

      this.$emit("handleClose", "selectSceneDialog");
    },
    //点击左侧的tab
    handleClickTab(idx, typeName) {
      this.clickIndex = idx;
      this.sceneType = this.sceneList[idx].sceneType;
      if (typeName == "未知场景") {
        let newData = [
          {
            sceneName: "未知场景"
          }
        ];
        this.$emit("getData", _.cloneDeep(newData));
        this.handleClose();
      } else {
        this.searchForm.pageIndex = 1;
        this.querySceneTypeList();
      }
    },
    //处理全选
    handleSelectAll(val) {
      if (!this.sceneTypeTableData.length) return;
      if (val) {
        this.sceneTypeTableData.map(item => {
          item.checked1 = true;
          item.checked2 = true;
          item.checked3 = true;
        });
      } else {
        this.sceneTypeTableData.map(item => {
          item.checked1 = false;
          item.checked2 = false;
          item.checked3 = false;
        });
      }
    },
    //查看未匹配数据明细
    lookNum() {
      this.numDialog = true;
    },
    //关闭问题数量弹框
    closeNumDialog() {
      this.numDialog = false;
    },
    //导出匹配数据
    exportErrorByData() {
      let data = this.scenePageResults.map(item => {
        return {
          场景名称: item.sceneName,
          查询结果: item.count
        };
      });
      exportXlsxByData({
        data,
        xlsxName: "匹配数据明细"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.select-scene-content {
  display: flex;
  .tree {
    width: 180px;
    height: 100%;
    .category-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      .category {
        width: 180px;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        position: relative;
        font-size: 14px;
        cursor: pointer;
      }
      .active {
        background: #f2f5fa;
        color: #46bfea;
        &::before {
          content: " ";
          display: block;
          width: 3px;
          height: 100%;
          background: #46bfea;
          position: absolute;
          top: 0px;
          left: 0px;
        }
      }
    }
  }
  .table {
    width: calc(100% - 180px);
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-left: 1px solid #edf0f5;
    /deep/.el-input {
      width: 200px;
      margin-bottom: 10px;
    }
    /deep/.cell {
      display: flex;
      justify-content: center;
    }
  }
}
/deep/.search-input {
  width: 280px;
  margin-bottom: 15px;
  .el-input__suffix {
    line-height: 28px;
  }
}
/deep/.el-table {
  th div {
    line-height: 26px !important;
    & > .el-checkbox {
      font-size: 12px !important;
      & > .el-checkbox__label {
        font-weight: 700;
        color: #48494c;
        font-size: 12px;
      }
    }
  }
}
.numHeader {
  padding: 20px;
  box-sizing: border-box;
  & > span {
    margin-right: 20px;
  }
}
</style>
